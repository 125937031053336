import React from 'react';



const BodyContract = ({ establishment }) => {

    // console.log('establishment', establishment)
    const styleParagraph = '  text-justify; my-2 '
    const styleTitle = 'my-3 fw-bold'
    const styleBold = 'fw-bold'


    return (
        <>
            <div className='d-flex flex-column col-xxl-12 mt-4   text-justify;'>

                <p className={styleParagraph}>Pelo presente instrumento particular, de um lado, na qualidade de licenciante, <span className='fw-bold'>UGET PDX TECNOLOGIA E
                    SOLUÇÕES LTDA</span>., inscrita no CNPJ sob o nº 33.399.652/0001-08, com sede na Rua Pitangueiras, nº 307,
                    Mirandópolis – São Paulo/SP, CEP: 04.052-020, neste ato representada por <span className='fw-bold'> Rafael Setrak Giglio</span>,  inscrito no <span className='fw-bold'>CPF  157.419.498-43</span>, email: <span className='fw-bold'>rafael@uget.com.br</span>, na forma de seu contrate social, doravante denominada apenas como <span className='fw-bold'>("UGET")</span>
                </p>

                <p className={styleParagraph}>E, na qualidade de licenciado, <span className={styleBold}>{establishment?.reason}</span>, inscrito no CNPJ/CPF sob nº CNPJ: {establishment?.cnpj},
                    localizado na Rua: {establishment?.address?.street},  {establishment?.address?.number}, na cidade de {establishment?.address?.city}, no estado de {establishment?.address?.state},
                    CEP:{establishment?.address?.zip_code}, neste ato representada por  <span className='fw-bold'>{establishment?.representative?.personal_name}</span>  inscrito no CNPJ/CPF :  <span className='fw-bold'>{establishment?.representative?.personal_cpf}</span>, email : <span className='fw-bold'>{establishment?.email}</span> na forma de seu contrato social,doravante denominado apenas como  <span className='fw-bold'>("CLIENTE")</span>;
                </p>

                <p className={styleParagraph}> As partes decidem firmar o presente Contrato de Licença de Software, Prestação de Serviços e Comodato de Máquinas, conforme as cláusulas e condições abaixo descritas. </p>

                <p className={styleParagraph}>Ao utilizar e realizar operações no Software <span className='fw-bold'>(uGet PDX –Ticket – Cashless – Hawker – Kitchin)</span> disponibilizado
                    pela UGET, o CLIENTE estará automaticamente aderindo e concordando com as condições deste Contrato.
                    A UGET poderá alterar as condições deste Contrato, a qualquer tempo, podendo o CLIENTE, caso não concorde
                    com a modificação, denunciá-lo a qualquer momento, conforme previsto adiante.</p>

                <h5 className={styleTitle}>
                    CONSIDERAÇÕES PRELIMINARES:
                </h5>

                <p className={styleParagraph}>O CLIENTE desde já declara que, em casos de epidemia / pandemia em que houver a implantação de medidas restritivas
                    às atividades realizadas por cada setor, seguirá e implementar todas as normas vigentes, isentando a UGET de qualquer
                    responsabilidade neste sentido e reconhecendo que a UGET não possui qualquer ingerência sobre os eventos e/ou
                    estabelecimentos do CLIENTE, sendo responsável exclusivamente pela concessão de licença de software de que trata
                    este Contrato.
                </p>

                <p className={styleParagraph}>Considerando que: </p>

                <p className={styleParagraph}>
                    I – A UGET é proprietária e legítima titular de software  <span className='fw-bold'>uGet PDX – Ticket – Cashless – Hawker – Kitchin,</span>
                    que permite ao CLIENTE realizar a gestão de consumo e vendas de seus clientes (“Usuários”), por meio da utilização das
                    funcionalidades disponíveis;
                </p>
                <p className={styleParagraph}>

                    II – A UGET é proprietária e titular de aplicativo para dispositivos móveis com acesso à internet,
                    denominado <span className='fw-bold'>uGet PDX – Ticket – Cashless – Hawker – Kitchin</span> que possibilita aos Usuários cadastrados realizarem
                    pagamentos em razão da compra de produtos ou serviços comercializados pelo CLIENTE, portanto, a UGET faz a
                    aproximação ou intermediação dos usuários e CLIENTE;

                </p>
                <p className={styleParagraph}>
                    III – A Plataforma e o Aplicativo são integrados com o sistema de pagamentos das Adquirentes e/ou Instituições de
                    Pagamento com quem a UGET possui relacionamento comercial, possibilitando que o CLIENTE receba e realize
                    pagamentos (“Serviços de Pagamento”); e

                </p>
                <p className={styleParagraph}>
                    IV – O serviço a ser licenciado compreende: uso do Software de pagamento e o acompanhamento de 1 (um) ou mais
                    técnicos para o suporte de acordo com a quantidade de equipamentos utilizados.
                    Resolvem as Partes celebrar este Contrato de Licença de Software e Prestação de Serviços (“Contrato”),
                    de acordo com as seguintes cláusulas e condições.
                </p>

                <h5 className={styleTitle}>
                    <span className='fw-bold'> 1 - DEFINIÇÕES E OBJETO </span>
                </h5>
                <p className={styleParagraph}>
                    <span className='fw-bold'>1.1.</span> As seguintes palavras e expressões, no singular ou plural, quando escritas neste
                    Contrato com sua primeira letra maiúscula, terão as seguintes definições:
                </p>
                <p className={styleParagraph}>Aplicativo ": software de propriedade e titularidade da UGET, utilizado através de dispositivos móveis e
                    totens de autoatendimento com conexão à internet, que permite aos Usuários consultar seus gastos e realizar
                    pagamentos ao CLIENTE.
                </p>
                <p className={styleParagraph}>
                    “Conteúdo”: informações contidas no Aplicativo, incluindo fotos, desenhos, artes gráficas, layouts, textos,
                    dados, preços, histórico de consumo, dentre outros, que serão disponibilizadas pelo CLIENTE aos Usuários.
                    “Adquirente”: ou subaquirente que possui relacionamento comercial com a UGET, e que realizará o credenciamento
                    do CLIENTE para aceitar e receber pagamentos com cartões de crédito e débito, bem como pagamentos via PIX.
                </p>
                <p className={styleParagraph}>
                    “Funcionalidades”: ferramentas disponibilizadas na Plataforma, que possibilitam ao CLIENTE: (i) disponibilizar
                    Conteúdo; (ii) permitir a realização de compras pelos Usuários; (iii) receber pagamentos realizados pelos
                    Usuários, através dos Serviços de Pagamento; (iv) utilizar o Módulo Fiscal (abaixo definido); e (iv) coordenar
                    a venda de produtos e serviços disponibilizados aos Usuários.
                </p>
                <p className={styleParagraph}>
                    “Instituição de Pagamento”: instituição com relacionamento comercial com a UGET, que presta serviços de gestão
                    e custódia de recursos financeiros depositados em contas de pagamento, processamento de transações de pagamento,
                    dentre outros serviços disponíveis.
                </p>
                <p className={styleParagraph}>
                    “Plataforma”: software de propriedade da UGET, para utilização online e off-line, por meio do qual o CLIENTE
                    poderá utilizar as funcionalidades disponibilizadas pela UGET.
                </p>
                <p className={styleParagraph}>
                    “Serviços”: serviços de tecnologia prestados pela UGET por meio da licença da Plataforma e do Aplicativo,
                    além da disponibilização de Funcionalidades, integração com os Serviços de Pagamento e auxílio no credenciamento
                    do CLIENTE perante uma Adquirente e/ou Instituição de Pagamento.

                </p>
                <p className={styleParagraph}>
                    “Serviços de Pagamento”: serviços de meios de pagamento prestados por uma Adquirente e/ou Instituição de
                    Pagamento, permitindo ao CLIENTE receber pagamentos dos Usuários mediante a utilização de cartões de crédito ou
                    débito (PIX), e realizar transações.
                </p>
                <p className={styleParagraph}>
                    “Usuário”: pessoa física que, após cadastrada perante a UGET, poderá acessar a Plataforma e ou Aplicativo,
                    através do caixa ou totem de autoatendimento para ter acesso ao Conteúdo disponibilizado pelo CLIENTE,
                    e realizar pagamentos decorrente da aquisição de produtos ou serviços disponibilizados pelo CLIENTE
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>1.2.</span> Por este Contrato, a UGET concede ao CLIENTE, a licença não exclusiva,
                    intransferível e onerosa da Plataforma, para utilização das Funcionalidades.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>1.2.1.</span> A Plataforma e o Aplicativo se encontram integrados com o sistema de uma Adquir
                    ente e/ou Instituição de Pagamento, permitindo ao CLIENTE a utilização dos Serviços de Pagamento, conforme as previsões
                    contratuais.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>1.3.</span> Também se inclui no objeto deste Contrato a prestação, pela UGET ao CLIENTE,
                    dos seguintes serviços (em conjunto “Serviços”):
                </p>
                <p className={styleParagraph}> (a) Entrega, em comodato, dos equipamentos necessários para realização de transações com cartão de crédito ou
                    débito e pagamentos através do PIX (“Equipamentos”) ;
                </p>
                <p className={styleParagraph}>
                    (b) Execução dos atos necessários para o credenciamento do CLIENTE ao sistema de uma Adquirente e/ou Instituição de Pagamento,
                    como a obtenção, análise e envio de informações e documentos (“Credenciamento”);
                </p>
                <p className={styleParagraph}>
                    (c) Suporte, treinamento e assessoria do CLIENTE para o uso da Plataforma, dos Equipamentos e dos Serviços de Pagamento; e
                </p>
                <di className={styleParagraph}>
                    <span className='fw-bold'>OBSERVAÇÃO:</span> Caso o CLIENTE já possua relacionamento com alguma Adquirente e/ou Instituição de
                    Pagamento que já seja parceira da UGET (PagSeguro), e tenha os equipamentos necessários para a realização de transações,
                    não será aplicável o disposto neste item, exceto se houver solicitação expressa do CLIENTE para a realização do comodato dos
                    equipamentos.
                </di>
                <p className={styleParagraph}>
                    <span className='fw-bold'>1.4.</span> Com o preenchimento da ORDEM DE SERVIÇO de Identificação (“ORDEM DE SERVIÇO”) e aceitação deste Contrato,
                    o CLIENTE poderá utilizar todas as Funcionalidades disponíveis na Plataforma, Serviços prestados pela UGET e Serviços de
                    Pagamento prestados por uma Adquirente e/ou Instituição de Pagamento, conforme aplicável.
                </p>

                <h5 className={styleTitle}>
                    2 – UTILIZAÇÃO DA PLATAFORMA
                </h5>

                <p className={styleParagraph}>
                    <span className='fw-bold'>2.1.</span> O CLIENTE deverá possuir hardwares compatíveis para utilizar a plataforma,
                    adequados às configurações e requisitos técnicos indicados no ORDEM DE SERVIÇO.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>2.1.1.</span> O CLIENTE será o único responsável por informar as características técnicas do local
                    para o uso dos softwares, como por exemplo: informar qual o melhor sinal de operadora de telecomunicação para o uso do 3G/4G;
                    providenciar a disponibilização de sinal de Internet (Wi-Fi) caso não tenha sinal adequado de operadoras,
                    dentre outras providências necessárias para a perfeita utilização do software licenciado.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>2.1.2.</span> É de exclusiva responsabilidade do CLIENTE a obtenção, manutenção e custeio dos
                    hardwares e softwares necessários, além da contratação do acesso à internet
                    (incluindo os tributos, tarifas ou encargos cobrados pelos fornecedores de serviços).
                </p>
                <p className={styleParagraph}>
                    <span className='fw-bold'>2.1.3.</span> A UGET não se responsabiliza por qualquer problema decorrente da impossibilidade
                    de utilização da Plataforma em razão da incompatibilidade dos hardwares e softwares, nem por problemas de conexão
                    ocasionados pela operadora de internet, vez que se trata de responsabilidade do CLIENTE informar as melhores condições
                    para o uso dos equipamentos, conforme item 2.1.1.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>2.2.</span> A Plataforma licenciada é de uso <span className='fw-bold'>exclusivo </span>do CLIENTE, não se permitindo a utilização
                    por outras empresas, ainda que do mesmo grupo econômico ou localizadas no mesmo endereço,
                    salvo quando expressamente autorizado pela UGET.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>2.3.</span> Sendo necessário, a UGET poderá ingressar nas dependências do CLIENTE para verificar a
                    correta utilização da Plataforma e para atualizar ou realizar manutenção preventiva e ou corretiva.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>2.3.1.</span> Se a Plataforma for utilizada de forma indevida pelo CLIENTE,
                    o Contrato poderá ser imediatamente rescindido, sem prejuízo de cobrança da Remuneração devida à UGET
                    até o fim da vigência contratual, a título de cláusula penal cumulativa e não compensatória.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>2.4.</span> A UGET poderá incluir novas Funcionalidades ou alterar suas características a qualquer momento,
                    devendo comunicar o CLIENTE previamente por e-mail, sem prejuízo dos avisos que serão disponibilizados
                    no site <span className='fw-bold'>www.ugetpdx.com.br</span>, o qual o CLIENTE se compromete a visitar frequentemente, visto que possui login e senha de acesso.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>2.5.</span> É de total responsabilidade do CLIENTE o correto fornecimento das informações acerca do produto a
                    ser fornecido. Cabe à este informar o valor, validade e eventuais especificações necessárias, além
                    de apresentar notas fiscais que comprovem a licitude dos itens. Desta forma, fica a UGET isenta de quaisquer
                    responsabilidades decorrentes de oferta de produtos ilícitos, tendo em vista que somente realiza a aproximação e
                    intermediação do negócio.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>2.6.</span> A UGET não garante a ininterrupção e a velocidade da Plataforma,
                    que poderá apresentar indisponibilidade, lentidão e erro de processamento, inclusive por tempo indeterminado,
                    em casos de manutenção preventiva ou corretiva, erro de sistema, falhas de outros prestadores de serviços e eventos
                    fortuitos ou força maior. Em tais situações, o CLIENTE reconhece que a UGET não responderá por perdas e danos de
                    qualquer natureza, tendo em vista a vistoria realizada e a possibilidade e imprevisibilidade de indisponibilidade dos serviços
                    de tecnologia.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>2.7.</span> A Plataforma não poderá ser utilizada para fins ilícitos, como captação ilegal de dados,
                    informações ou qualquer atividade que possa causar danos à UGET, Usuários e Adquirente e/ou Instituições de Pagamento.
                </p>

                <h5 className={styleTitle}>
                    3 – CREDENCIAMENTO PARA UTILIZAÇÃO DOS SERVIÇOS DE PAGAMENTO
                </h5>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.1.</span> Os Serviços de Pagamento serão prestados por uma Adquirente, possibilitando o recebimento
                    de pagamentos realizados por meio de cartão de débito e crédito.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.2.</span> O CLIENTE autoriza que a UGET proceda com seu Credenciamento perante uma Adquirente
                    e/ou Instituição de Pagamento, mediante o fornecimento de todas as informações e documentos necessários, nos termos indicados na ORDEM DE SERVIÇO, para a utilização dos Serviços de Pagamento.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.2.1.</span> A Adquirente prestadora dos Serviços de Pagamento será definida pela UGET e informada ao CLIENTE, quando solicitado.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.2.2.</span> A Instituição de Pagamento se encontra indicada na ORDEM DE SERVIÇO, e a gestão e custódia
                    de recursos e realização de pagamentos, por conta e ordem do CLIENTE.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.3.</span> O CLIENTE autoriza o compartilhamento de informações e documentos com a Adquirente
                    e/ou Instituição de Pagamento para a realização do Credenciamento.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.3.1.</span>  O CLIENTE outorga à UGET, através deste Contrato, os poderes para a prática de todos os
                    atos necessários ao seu credenciamento.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.3.2.</span> O CLIENTE se responsabiliza, civil e criminalmente, pela veracidade de todos os dados,
                    documentos e informações fornecidas e declaradas, inclusive perante a Adquirente e/ou Instituição de Pagamento, obrigando-se
                    a manter a UGET ciente sobre qualquer alteração em seus dados, para que sejam devidamente atualizados.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.4.</span> O CLIENTE será considerado credenciado a partir da disponibilização dos Serviços de Pagamento.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.4.1.</span> Os Serviços prestados pela UGET em razão deste Contrato não garantem o efetivo Credenciamento do CLIENTE,
                    podendo as Adquirentes e/ou Instituições de Pagamento, deixar de credenciar qualquer pessoa física ou jurídica
                    que não atenda as condições necessárias.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.4.2.</span> O CLIENTE poderá, a qualquer momento, solicitar à UGET envio de cópia do contrato de
                    credenciamento celebrado com a Adquirente.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.5.</span>  A Adquirente e/ou Instituição de Pagamento será a única responsável pela
                    prestação dos Serviços de Pagamento e por eventuais cancelamentos, suspensões ou estorno de pagamentos em favor do CLIENTE.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.5.1.</span> A UGET não se responsabiliza pelo descumprimento de qualquer obrigação atinente aos
                    Serviços de Pagamento, devendo o CLIENTE adotar as medidas necessárias perante a Adquirente e/ou Instituição de Pagamento.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.5.2.</span> A UGET não pagará qualquer indenização, independentemente da sua natureza
                    (inclusive por danos emergentes, lucros cessantes, perda de receita ou danos morais), caso o CLIENTE venha a ter
                    prejuízo em razão de erros e problemas nos Serviços de Pagamento.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.6.</span> As prestadoras do Serviço de Pagamento estarão sujeitas ao pagamento de remuneração específica à UGET, de acordo com o ORDEM DE SERVIÇO,
                    que prevê as taxas e tarifas cobradas pela Adquirente e/ou Instituição de Pagamento.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.6.1.</span> Em caso de alteração de taxas ou tarifas cobradas pelas Adquirentes e/ou Instituições de Pagamento,
                    a UGET poderá reajustar sua remuneração em decorrência dos Serviços de Pagamento.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.7.</span> O CLIENTE está ciente e concorda que a UGET, terá acesso às informações referentes às transações
                    de pagamento realizadas em razão dos Serviços de Pagamento.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>3.8.</span> A UGET poderá, mediante prévia comunicação ao CLIENTE, substituir a Adquirente e/ou Instituição
                    de Pagamento. Havendo a substituição, a UGET se responsabilizará por todos os custos referentes à transferência do serviço,
                    sem qualquer ônus ao CLIENTE.
                </p>

                <h5 className={styleTitle}>
                    4 – CHARGEBACK E CONTESTAÇÃO DAS TRANSAÇÕES COM CARTÃO
                </h5>

                <p className={styleParagraph}>
                    <span className='fw-bold'>4.1.</span> Nas transações realizadas com cartão de crédito ou débito perante a
                    Adquirente, aplicam-se ao CLIENTE as regras de Chargeback e cancelamento estabelecidas pela Adquirente e cartões,
                    bem como as multas e penalidades aplicáveis à UGET pela Adquirente ou bandeiras, em caso de descumprimento,
                    pelo CLIENTE das regras e procedimentos de Chargeback definidos pelas bandeiras e Adquirentes.
                </p>
                <p className={styleParagraph}>
                    <span className='fw-bold'>4.2.</span> Quando solicitado, o CLIENTE deverá fornecer comprovante de venda impresso no Equipamento e
                    toda a documentação referente à comprovação da entrega dos produtos/serviços para afastar a contestação por Chargeback,
                    sendo que a não apresentação destes documentos será interpretada como falta de entrega do produto/serviço.
                </p>
                <p className={styleParagraph}>
                    <span className='fw-bold'>4.3.</span> Havendo indícios de irregularidade na transação com cartão de crédito e
                    débito, face ao não reconhecimento pelo Usuário, reclamação, Chargeback ou cancelamento de um pagamento
                    recebido pelo CLIENTE, a Adquirente poderá reter os valores temporariamente, que serão compensados
                    com os créditos a serem pagos para cobrir o valor da obrigação.
                </p>
                <p className={styleParagraph}>
                    <span className='fw-bold'>4.3.1.</span> O CLIENTE declara-se ciente da possibilidade de suspensão do pagamento das
                    transações com cartão de crédito e débito, pelo tempo necessário para a apuração de eventual Chargeback.
                </p>

                <h5 className={styleTitle}>
                    5 - HIPÓTESES DE RETENÇÃO E COMPENSAÇÃO DE VALORES
                </h5>

                <p className={styleParagraph}>
                    <span className='fw-bold'>5.1. </span> O CLIENTE reconhece e concorda que a UGET poderá realizar a: (i) retenção de quantias devidas ao CLIENTE para garantir
                    que quaisquer pagamentos devidos à UGET sejam realizados integralmente, ou
                    para resguardar a UGET contra eventuais riscos financeiros decorrentes de obrigações do CLIENTE, e (ii) compensação,
                    com quaisquer quantias devidas ao CLIENTE, de débitos de qualquer natureza do CLIENTE perante a UGET, em conformidade
                    com as disposições deste Contrato.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>5.2.</span> A seu exclusivo critério a UGET poderá reter qualquer pagamento que o CLIENTE
                    tenha a receber da Adquirente e/ou Instituição de Pagamento, quando houver alto risco operacional ou de crédito relacionado
                    ao desempenho do CLIENTE.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>5.3.</span> Nos casos em que se verificar a iliquidez, insolvência, pedido de
                    recuperação judicial ou extrajudicial, pré-falência, encerramento de atividades ou qualquer outra hipótese em
                    que ficar caracterizada a dificuldade do CLIENTE em cumprir suas obrigações, a UGET reserva-se, mediante
                    aviso ao CLIENTE, o direito de reter os créditos a ele devidos.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>5.4.</span> Quando for verificado pela UGET que os equipamentos cedidos não
                    foram devolvidos integralmente, poderá reter o valor dos equipamentos faltantes, devendo, para tanto,
                    comunicar o CLIENTE acerca da retenção realizada dentro do prazo de 24 horas da retenção.
                    Ainda, poderá haver retenção de valores quando houver o risco de cancelamento ou chargeback das transações.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>5.5.</span> Nas hipóteses de Chargeback, cancelamento, entre outras situações de não reconhecimento
                    ou contestação do valor da transação pelos Usuários junto aos bancos emissores, bem como nos casos de cobranças de taxas,
                    tarifas, produtos, equipamentos, aluguéis, transações irregulares etc., a UGET poderá, alternativamente: (i) deixar de efetuar
                    o pagamento dos valores na Conta de Pagamento ou Domicílio Bancário; (ii) realizar lançamentos a débito na Conta de Pagamento
                    do CLIENTE; (iii) compensar o valor do débito com quaisquer outros créditos, presentes ou futuros, devidos ao CLIENTE,
                    debitando os eventuais encargos incidentes na forma deste Contrato; (iv) permitir que o CLIENTE, no caso de ausência de
                    créditos a compensar, realize o pagamento mediante a forma que vier a ser indicada pela UGET; ou (v) efetuar cobrança por
                    terceiros.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>5.5.1.</span> Em caso de atraso do pagamento, o CLIENTE estará sujeito a aplicação de atualização
                    monetária pelo índice IGPM/FGV – ou outro índice que vier a substituí-lo – e juros de 1% ao mês, calculado pro rata die,
                    bem como multa contratual de 10% (dez) por cento do valor inadimplido.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>5.5.2.</span>  O CLIENTE terá o prazo de 30 (trinta) dias para apontar eventual divergência em relação aos
                    pagamentos realizados. Após o prazo, o CLIENTE dará a plena, ampla e definitiva quitação à UGET, à Adquirente e/ou Instituição
                    de Pagamento, não restando nada a ser reclamado pelo CLIENTE.

                </p>

                <h5 className={styleTitle}>
                    6 - EQUIPAMENTOS NECESSÁRIOS PARA A UTILIZAÇÃO DOS SERVIÇOS DE PAGAMENTO (COMODATO)
                </h5>

                <p className={styleParagraph}>
                    <span className='fw-bold'>6.1.</span> O CLIENTE deverá utilizar equipamento específico para desfrutar dos Serviços de
                    Pagamento prestados pela Adquirente, sendo que, caso o CLIENTE não possua o Equipamento adequado, estes poderão ser
                    disponibilizados pela UGET em comodato.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>6.2.</span>  A UGET assegura que o Equipamento se encontra em condições de uso, conservação e
                    funcionamento, devendo ser realizada, tanto na entrega quanto na devolução, vistoria para atestar o estado dos equipamentos,
                    que deverão ser devolvidos nas mesmas condições da entrega. Servirá a vistoria como comprovante das condições/ estado de
                    entrega e devolução.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>6.3.</span> A UGET cederá seus Equipamentos em comodato para a utilização pelo
                    CLIENTE em seu(s) Evento(s) e/ou Estabelecimento(s), sendo que em caso de os Equipamentos não serem devolvidos
                    à UGET, será cobrado por cada unidade, à título de reembolso/indenização, o valor estipulado na ORDEM DE SERVIÇO.
                    Referido valor está sujeito a variações de preços de mercado, caso em que a UGET deverá notificar previamente o
                    CLIENTE em caso de necessidade de reajuste, visando o equilíbrio econômico deste instrumento.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>6.3.1.</span>   O início do comodato é fixado a partir da data do efetivo recebimento do
                    Equipamento pelo CLIENTE e se encerra com a finalização do evento, sendo que, no caso de não devolução dos
                    equipamentos o CLIENTE arcará com os valores previstos na ORDEM DE SERVIÇO.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>6.3.2.</span>   Caso não haja o pagamento dos valores decorrentes da não devolução dos
                    equipamentos dentro do prazo de (48) quarenta e oito horas, haverá a incidência de multa de 20% sobre o valor total
                    devido, bem como aplicação dos juros legais.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>6.4.</span>  A manutenção corretiva ou substituição do Equipamento defeituoso deverá ser
                    realizada pela UGET, sem prejuízo para o CLIENTE, salvo quanto à troca de suprimentos, tais como bobinas e baterias.
                    Restando comprovada a má utilização pelo CLIENTE, este arcará com os custos da manutenção/substituição.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>6.5.</span>  O CLIENTE declara-se ciente da infraestrutura necessária para o
                    adequado uso do Equipamento (internet, bobinas, cartões....), se responsabilizando integralmente,
                    por garantir a perfeita estrutura para utilização do Equipamento (item 2.1.1.) e por tais custos.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>6.6.</span>  O CLIENTE deverá (a) Notificar imediatamente a UGET acerca de quaisquer problemas
                    ocorridos com o Equipamento; (b) Zelar para que todo e qualquer conserto do Equipamento seja efetuado única e exclusivamente
                    pela UGET ou por pessoa por esta indicada; (c) Não emprestar, arrendar, sublocar ou ceder o Equipamento a terceiros,
                    a qualquer título, bem como não transferi-los sem prévia e expressa autorização da UGET;
                    (d) Indenizar a UGET por danos decorrentes de sinistros, com destruição parcial ou total, motivada por queda,
                    uso inadequado ou indevido, negligência, imperícia, quebra do lacre de garantia, imprudência ou caso de roubo, furto,
                    extravio, pagando à UGET o valor de mercado do Equipamento, a título de multa não compensatória; (e) Em caso de roubo ou
                    furto, o CLIENTE obriga-se a providenciar o respectivo boletim de ocorrência perante o órgão competente em até 24
                    (vinte e quatro) horas após o fato e a enviar uma cópia do boletim de ocorrência para a UGET, no prazo de 48
                    (quarenta e oito) horas do fato; e; (f) Arcar com os custos referentes ao serviço de instalação não realizada.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>6.7.</span> A UGET não se responsabilizará por problemas nos Equipamentos fornecidos por terceiros
                    e que interfiram na utilização plena da Plataforma.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>6.8.</span> O CLIENTE se responsabiliza por qualquer tipo de clonagem ou fraudes relativas
                    ao uso inadequado do Equipamento.


                </p>

                <h5 className={styleTitle}>
                    7 - REMUNERAÇÃO DA UGET
                </h5>

                <p className={styleParagraph}>
                    <span className='fw-bold'>7.1.</span> Em contrapartida à licença de uso da Plataforma e prestação dos Serviços
                    previstos neste Contrato, o CLIENTE pagará à UGET remuneração de acordo com os valores descritos
                    na ORDEM DE SERVIÇO (“TAXAS DE REMUNERAÇÃO UGET”).

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>7.2.</span> O pagamento da Remuneração devida à UGET se dará mediante transferência
                    pela Adquirente e/ou Instituição de Pagamento do valor das transações realizadas com cartão de crédito ou débito
                    e via PIX, antes de realizar o pagamento ao CLIENTE.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>7.2.1.</span> O CLIENTE declara expressamente estar ciente que a UGET realizará o serviço de intermediação
                    de valores, de modo que o valor auferido no evento será transferido para a conta da UGET que,
                    dentro do prazo máximo de 24 (vinte e quatro) horas realizará a transferência do valor ao CLIENTE,
                    excetuado o valor devido à UGET à título de remuneração.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>7.3.</span> A Remuneração será variável, sendo cobrado o percentual de acordo com os
                    percentuais descritos nA ORDEM DE SERVIÇO (“Remuneração”), sobre todas as transações realizadas, inclusive em
                    dinheiro. Tal percentual será descontado dos recebíveis via cartões de crédito e débito, bem como PIX.

                </p>


                <p className={styleParagraph}>
                    <span className='fw-bold'>7.3.1.</span>  O CLIENTE deverá pagar o valor mínimo da Remuneração, independente do
                    volume de transações realizadas. O valor mínimo trata-se de valor fixado por máquina ou o percentual,
                    estipulado na ORDEM DE SERVIÇO (“Remuneração”)., sobre o valor total arrecadado, o que for maior ao término do
                    evento ou contrato.

                </p>


                <p className={styleParagraph}>
                    <span className='fw-bold'>7.3.2.</span>  O valor da Remuneração será reajustado, anualmente, pela variação positiva do
                    IGPM/FGV ou outro índice que vier a substituí-lo.

                </p>


                <p className={styleParagraph}>
                    <span className='fw-bold'>7.4.</span> O não pagamento da Remuneração ensejará em multa de 10% (dez por cento),
                    juros moratórios de 1% (um por cento) ao mês e correção monetária pelo IGPM/FGV ou outro índice que vier a substituí-lo,
                    calculados pro rata die sobre o valor do débito.

                </p>


                <p className={styleParagraph}>
                    <span className='fw-bold'>7.4.1.</span> Caso não haja o pagamento, os Serviços poderão ser imediatamente
                    suspensos, além da possibilidade de adoção das medidas judiciais e extrajudiciais cabíveis para o recebimento do
                    valor, inclusive a inclusão do débito nos órgãos de proteção ao crédito.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>7.5.</span> O CLIENTE que não utilizar a Plataforma, por qualquer motivo que seja,
                    como cancelamento ou interdição do evento, deverá arcar com o Valor Mínimo previsto, tendo em vista os custos
                    e despesas da UGET. Em casos de adiamento do evento, será realizada nova ORDEM DE SERVIÇO, que poderá apresentar
                    valores diversos em razão do decurso de tempo.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>7.6.</span> O valor da Remuneração será reajustado, automaticamente, sempre que houver
                    a alteração dos tributos incidentes sobre o objeto deste Contrato.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>7.7.</span> Em caso de prestação de serviços adicionais ao CLIENTE, a UGET poderá aplicar outras modalidades de remuneração,
                    conforme o valor indicado na ORDEM DE SERVIÇO.

                </p>

                <h5 className={styleTitle}>
                    8 – OBRIGAÇÕES ADICIONAIS DO CLIENTE
                </h5>

                <p className={styleParagraph}>
                    <span className='fw-bold'>8.1.</span> A UGET não realizará qualquer controle/fiscalização dos produtos ou serviços comercializados pelo CLIENTE, sendo este o único responsável pela qualidade,
                    quantidade, entrega, garantia e veracidade dos produtos/serviços comercializados.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>8.1.1.</span> O CLIENTE declara que todos os produtos por ele comercializados provém de origem lícita, sendo acompanhados por todas as notas fiscais necessárias. Desta forma, a UGET
                    se isenta de qualquer responsabilidade decorrente de eventuais fraudes cometidas pelo CLIENTE.

                </p>
                <p className={styleParagraph}>
                    <span className='fw-bold'>8.2.</span> O CLIENTE é o único responsável por todas as obrigações decorrentes
                    da atividade empresarial, tais como obrigações fiscais, trabalhistas, cíveis, consumeristas, entre outras,
                    estando a UGET isenta de quaisquer responsabilidades.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>8.3.</span> Através da Plataforma, o CLIENTE poderá se valer de uma Funcionalidade
                    que permite realizar a gestão das vendas aos Usuários, possibilitando a emissão de notas fiscais através do
                    envio de informações a serem fornecidas exclusivamente pelos CLIENTES. (“Módulo Fiscal”).

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>8.3.1.</span> O CLIENTE se responsabiliza, com exclusividade, por toda e qualquer
                    informação prestada ao utilizar o Módulo Fiscal, incluindo os aspectos contábeis, fiscais, entre outros,
                    sendo responsável pela qualidade, segurança e acuidade de qualquer informação necessária.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>8.3.2.</span>  A UGET é responsável somente pelo fornecimento do Módulo Fiscal,
                    de modo que qualquer erro na utilização do Módulo Fiscal e/ou na emissão de notas fiscais será de exclusiva
                    responsabilidade do CLIENTE; que se compromete a isentar a UGET de quaisquer ônus ou responsabilidade,
                    inclusive perante as secretarias da fazenda municipais, estaduais e federais.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>8.4.</span> Caberá ao CLIENTE dirimir diretamente com os Usuários eventuais reclamações
                    relacionadas com os produtos ou serviços comercializados pelo Aplicativo.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>8.5.</span> O CLIENTE se declara ciente e concorda que a UGET: (i) presta serviços de tecnologia
                    e não realiza qualquer fiscalização sobre os Usuários; (ii) não se responsabiliza pela idoneidade financeira dos
                    Usuários e não garante o pagamento pela venda de qualquer produto ou serviço comercializado pelo CLIENTE;
                    e (iii) não presta qualquer garantia pelos Serviços de Pagamento integrados na Plataforma.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>8.6.</span> O CLIENTE assume total responsabilidade pelos casos em que a Plataforma for afetada em razão da utilização de
                    programas externos, bem como pela instalação de antivírus e outros softwares de segurança.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>8.7.</span> O CLIENTE se compromete, ainda, a (i) manter a Plataforma atualizada, objetivando correção de erros,
                    melhorias e adaptações indicadas pela UGET; e (ii) criar backups (cópias de segurança) dos dados obtidos em razão da utilização
                    da Plataforma, comprometendo-se a armazená-los em servidores protegidos e seguros.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>8.8.</span> O CLIENTE é o único responsável pelo recrutamento, seleção, contratação,
                    administração e gerenciamento de seus funcionários e subcontratados, de modo que a UGET não possui qualquer
                    relação de emprego com os funcionários e/ou subcontratados do CLIENTE.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>8.9.</span>  Na hipótese de ajuizamento de processos judiciais e/ou administrativos
                    de qualquer natureza, inclusive trabalhista, fiscal, civil ou consumerista, contra a UGET, seus sócios,
                    executivos, empregados ou prepostos (“Partes Indenes”), relativamente aos Usuários, funcionários,
                    subcontratados ou quaisquer pessoas envolvidas pelo CLIENTE em sua atividade, o CLIENTE se obriga a
                    assumir de imediato o processo, na qualidade de única parte legítima, reivindicando para si a responsabilidade
                    pelas obrigações exigidas nos referidos processos e requerendo a exclusão das Partes Indenes do polo passivo
                    do processo, isentando-as de qualquer responsabilidade solidária ou subsidiária.

                </p>

                <h5 className={styleTitle}>
                    9 – PRIVACIDADE DAS INFORMAÇÕES
                </h5>

                <p className={styleParagraph}>
                    <span className='fw-bold'>9.1.</span>   A UGET possui uma Política de Privacidade que indica como as informações
                    do CLIENTE e dos Usuários serão coletadas, utilizadas, armazenadas, tratadas, divulgadas e protegidas,
                    devendo o CLIENTE ler atentamente a Política de Privacidade, que é parte integrante deste Contrato.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>9.1.1</span>  A UGET adota todas as medidas necessárias e se utiliza de tecnologias adequadas para proteger a coleta,
                    processamento e armazenamento das informações, porém não pode assegurar que terceiros não autorizados se utilizem de meios
                    fraudulentos para o furto, uso indevido, alteração ou acesso não autorizado das informações do CLIENTE ou Usuários.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>9.2.</span>  A UGET auxiliará e cooperará com qualquer autoridade judicial, reguladora
                    ou órgão público que venha a solicitar informações, podendo fornecer quaisquer informações sobre o CLIENTE ou a
                    utilização da Plataforma.

                </p>

                <h5 className={styleTitle}>
                    10 – PROPRIEDADE INTELECTUAL
                </h5>

                <p className={styleParagraph}>
                    <span className='fw-bold'>10.1.</span> A UGET é legítima detentora de todos os direitos referentes ao
                    código-fonte da Plataforma e do Aplicativo, incluindo o método de funcionamento, APIs de integração,
                    fotografias, imagens, logomarcas, projetos, marcas, textos, gráficos, ícones, clipes de áudio,
                    compilação de dados, vídeos, sinais distintivos e informações.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>10.2.</span> É vedado ao CLIENTE qualquer tipo de reprodução, total ou parcial,
                    permanente ou provisória, de forma gratuita ou onerosa, mesmo que sem alterações, da Plataforma,
                    do Aplicativo e de suas Funcionalidades, sem a expressa autorização escrita da UGET,
                    ainda que sejam realizados como referências e/ou propagandas em websites ou qualquer outro meio de
                    veiculação de informações, sob pena de aplicação das sanções previstas em lei, bem como,
                    violação de direitos autorais.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>10.3.</span> O CLIENTE reconhece que a Plataforma e todas as suas Funcionalidades
                    são protegidos pela legislação de direitos autorais e de proteção aos programas de computador,
                    sendo a UGET exclusiva titular de todos e quaisquer direitos de propriedade intelectual, incluindo direitos
                    autorais e segredos de negócio.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>10.4.</span> É vedado ao CLIENTE: (i) transferir, comercializar, sub licenciar,
                    autorizar o uso por terceiros ou, de qualquer outra forma, alienar a Plataforma; (ii) efetuar modificações,
                    acréscimos ou derivações na Plataforma, por si ou por terceiros; (iii) fazer engenharia reversa, descompilar
                    ou desmontar a Plataforma, ou adotar qualquer outra medida que possibilite o acesso ao código-fonte da
                    Plataforma; e (iv) copiar, total ou parcialmente, a Plataforma, ou usá-la de modo diverso do expressamente
                    estipulado neste Contrato.

                </p>

                <h5 className={styleTitle}>
                    11 – PRAZO DE VIGÊNCIA E EXTINÇÃO
                </h5>


                <p className={styleParagraph}>
                    <span className='fw-bold'>11.1.</span> Este Contrato entra em vigor a partir da assinatura e vigerá pelo prazo de 24 meses.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>11.2.</span> Este Contrato poderá ser considerado extinto, imediatamente e sem a
                    necessidade de aviso prévio, em caso de: (i) descumprimento de qualquer obrigação prevista neste Contrato,
                    por período superior a 10 (dez) dias contados do recebimento de notificação pela Parte Infratora;
                    (ii) inadimplemento, por parte do CLIENTE, da Remuneração devida à UGET, por período superior a 30 (trinta) dias;
                    ou (iii) suspensão, pelas autoridades competentes, da execução deste Contrato.

                </p>


                <p className={styleParagraph}>
                    <span className='fw-bold'>11.3.</span> Caso a denúncia venha a ser exercida pelo CLIENTE antes do decurso do
                    Prazo Inicial, este deverá pagar a multa, cumulativa e não indenizatória, equivalente à 03 (três) vezes a média
                    do valor correspondente aos últimos 03 (três) meses da Remuneração paga pelo CLIENTE à UGET; ou do valor da
                    parcela única (em caso de eventos).

                </p>


                <p className={styleParagraph}>
                    <span className='fw-bold'>11.3.1.</span> A multa acima deverá ser calculada de forma proporcional ao
                    período restante para o término do Prazo Inicial, devendo ser paga pelo CLIENTE no prazo de 15 (quinze)
                    dias contados da notificação enviada pela UGET, sob pena de multa moratória de 10% (dez por cento),
                    juros moratórios de 1% (um por cento) ao mês e correção monetária pelo IGPM-FGV ou outro índice que vier
                    a substituí-lo.

                </p>


                <p className={styleParagraph}>
                    <span className='fw-bold'>11.3.2.</span>  Caso a denúncia venha a ser exercida pelo CLIENTE após o término do
                    Prazo Inicial, ou pela UGET a qualquer tempo, a resilição ocorrerá livre de direitos indenizatórios, ônus,
                    encargos ou penalidades, exceto pelas obrigações contratuais pendentes, que deverão ser cumpridas até o seu
                    término, na forma deste Contrato.

                </p>
                <p className={styleParagraph}>
                    <span className='fw-bold'>11.4.</span>  A UGET não será responsável pelo armazenamento de dados ou transferência
                    para terceiros, sendo obrigação do CLIENTE a realização de backup das informações da Plataforma.


                </p>

                <h5 className={styleTitle}>
                    12 - CONDUTA ÉTICA
                </h5>

                <p className={styleParagraph}>
                    <span className='fw-bold'>12.1.</span>  Durante a vigência deste Contrato, o CLIENTE, por si e por suas subsidiárias,
                    controladas e coligadas, bem como por seus respectivos sócios, diretores, conselheiros, administradores, executivos,
                    empregados, prepostos, subcontratados, representantes e procuradores, expressamente concorda que:
                    (i) deverá cumprir e respeitar de forma ampla e geral as leis e regulamentações aplicáveis, incluindo, mas não se limitando,
                    à Lei Federal nº 12.846/2013, aos artigos 317 e 333 do Código Penal Brasileiro, à Lei Complementar nº 101/00, à Lei nº 12.529/11
                    e às demais leis brasileiras relacionadas à atos de corrupção passiva e ativa, direito concorrencial, responsabilização fiscal
                    e fraude; e (ii) não está recebendo qualquer valor ou recursos para realização de subornos ou quaisquer outros atos que violem
                    o exposto nesta cláusula.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>12.2.</span> O CLIENTE concorda e compromete-se a: (i) nunca receber, propor ou
                    prometer pagar, seja direta ou indiretamente, qualquer benefício indevido a um funcionário/agente público,
                    a um terceiro ligado a ele, ou a qualquer prestador de serviço com relação ao assunto deste Contrato como
                    propósito de influenciar ou induzir qualquer ação ou decisão de um funcionário público ou terceiro;
                    (ii) não fraudar, manipular ou impedir qualquer licitação relacionada a este Contrato ou a execução de
                    algum contrato administrativo dele decorrente; (iii) não solicitar ou obter vantagem ilícita ao negociar
                    alterações ou prorrogações a contratos públicos eventualmente relacionados com este Contrato; e
                    (iv) não impedir investigações ou inspeções feitas por funcionários/agentes públicos.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>12.2.1.</span> Adicionalmente, o CLIENTE concorda em notificar a UGET imediatamente
                    por escrito, caso tome conhecimento que algum de seus sócios, diretores, conselheiros, administradores,
                    executivos, empregados, prepostos, subcontratados e procuradores atuando em seu nome, recebam solicitação
                    de algum funcionário público ou terceiro pedindo ou propondo pagamentos ilícitos e se compromete a enviar
                    todas as informações e documentos relacionados se solicitado pela UGET.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>12.2.2.</span> Os termos “benefício indevido / vantagem ilícita”, descritos na cláusula acima, devem ser
                    compreendidos como qualquer oferta, presente/brinde, pagamento, promessa de pagamento ou autorização de pagamento de qualquer
                    valor ou qualquer coisa de valor (incluindo, mas não limitando-se a, refeições, entretenimento, despesas de viagens),
                    direta ou indiretamente, para o uso ou benefício de qualquer funcionário/agente público, terceiro relacionado a tal funcionário
                    público, ou a qualquer outro terceiro com o propósito de influenciar qualquer ação, decisão ou omissão por parte de um
                    funcionário público ou terceiro para obter, reter ou direcionar negócios, ou garantir algum tipo de benefício ou vantagem
                    imprópria às partes, seus clientes, afiliadas ou qualquer outra pessoa.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>12.2.3.</span> O termo “funcionário/agente público” descrito na cláusula acima deve
                    ser compreendido como: (i) qualquer indivíduo que, mesmo que temporariamente e sem compensação, esteja a serviço,
                    empregado ou mantendo uma função pública em entidade governamental, entidade controlada pelo governo,
                    ou entidade de propriedade do governo (indivíduos empregados por fundos de pensão públicos devem ser
                    considerados “funcionários/agentes públicos” para o propósito deste Contrato), nacional ou estrangeira,
                    ou em organizações públicas; (ii) qualquer indivíduo que seja candidato ou esteja ocupando um cargo público;
                    (iii) qualquer partido político ou representante de partido político. As mesmas exigências e restrições também
                    se aplicam aos familiares de funcionários públicos até o segundo grau
                    (cônjuges, filhos e enteados, pais, avós, irmãos, tios e sobrinhos).

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>12.3.</span> O descumprimento do disposto nesta Cláusula ou de quaisquer
                    leis anticorrupção pelo CLIENTE será considerado infração grave a este Contrato e conferirá à UGET
                    o direito de rescindi-lo imediatamente, com a possibilidade de suspensão e retenção de todo e qualquer
                    pagamento relacionado ao presente Contrato, a fim de ressarcir eventuais prejuízos sofridos.

                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>12.4.</span> O CLIENTE indenizará e isentará a UGET e/ou diretores, conselheiros,
                    funcionários e/ou representantes, de qualquer perda, reivindicação, multa, custa ou quaisquer despesas
                    incorridas pelo CLIENTE decorrentes de qualquer violação prevista nesta Cláusula. Sem prejuízo das medidas
                    legais cabíveis, o CLIENTE reconhece e concorda que a UGET fornecerá dados e informações pertinentes,
                    quando solicitado pelas autoridades competentes, na hipótese de instauração de qualquer procedimento
                    cujo objeto for a apuração de violação das leis anticorrupção aplicáveis a este Contrato.

                </p>

                <h5 className={styleTitle}>
                    13 – DISPOSIÇÕES GERAIS
                </h5>

                <p className={styleParagraph}>
                    <span className='fw-bold'>13.1.</span> Este Contrato não gera qualquer direito de exclusividade às Partes,
                    bem como nenhum outro direito ou obrigação diverso daqueles aqui expressamente previstos, ficando afastada
                    qualquer relação, ostensiva ou remota, de sociedade, joint-venture ou associação entre as Partes,
                    não estando nenhuma delas autorizada a assumir obrigações ou compromissos em nome da outra.
                </p>

                <p className={styleParagraph}>
                    <span className='fw-bold'>13.2.</span> A eventual tolerância por qualquer das Partes quanto a qualquer violação
                    dos termos e condições deste Contrato será considerada mera liberalidade e não será interpretada como novação,
                    precedente invocável, renúncia a direitos, alteração tácita dos termos contratuais, direito adquirido ou
                    alteração contratual.
                </p>


                <p className={styleParagraph}>
                    <span className='fw-bold'>13.3.</span> A nulidade ou invalidade de qualquer das disposições deste Contrato não implicará na nulidade ou invalidade das demais,
                    devendo as cláusulas nulas ou inválidas serem novamente redigidas.
                </p>


                <p className={styleParagraph}>
                    <span className='fw-bold'>13.4.</span>  O CLIENTE autoriza a UGET a incluir, sem qualquer ônus ou encargos, seu nome, marcas e logotipos,
                    endereço, em ações de marketing, catálogos e/ou em qualquer outro meio ou material promocional utilizado pela UGET,
                    inclusive a comunicação de seus dados, tais como: nome, endereço, nome fantasia, telefone, site, e-mail, ramo de atividade
                    entre outros dados, ressalvado o direito de o CLIENTE revogar esta autorização a qualquer momento.
                </p>


                <p className={styleParagraph}>
                    <span className='fw-bold'>13.5.</span> Os termos deste contrato prevalecem sobre qualquer acordo ou contrato prévio, escrito ou verbal, que tenha sido
                    levado a efeito pelas Partes com relação ao seu objeto.
                </p>


                <p className={styleParagraph}>
                    <span className='fw-bold'>13.6.</span> As Partes elegem o Foro da Cidade de São Paulo / SP como único competente para dirimir as questões que porventura surgirem na execução do presente Contrato,
                    com renúncia expressa a qualquer outro, por mais privilegiado que seja.
                </p>


                <p className={styleParagraph}>
                    E assim, por estarem justas e contratadas, as partes e as testemunhas envolvidas neste instrumento
                    afirmam e declaram que esse poderá ser assinado eletronicamente através da plataforma <span className='fw-bold'>D4Sign</span>,
                    atualmente no endereço <span className='fw-bold'>www.d4sign.com.br</span>, com fundamento no Artigo 10, parágrafo 2º da MP 2200-2/2001,
                    e do Artigo 6º do Decreto 10.278/2020, sendo as assinaturas consideradas válidas, vinculantes e executáveis,
                    desde que firmadas pelos representantes legais das Partes. Consigna-se no presente instrumento que a
                    assinatura com Certificado Digital/eletrônica tem a mesma validade jurídica de um registro e autenticação
                    feita em cartório, seja mediante utilização de certificados e-CPF, e-CNPJ e/ou NF-e, produzindo seus
                    jurídicos e legais efeitos.

                </p>


            </div>
        </>
    );

}

export default BodyContract;