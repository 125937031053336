import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Button from '../../../../components/bootstrap/Button';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import SelectEvent from '../../../../components/MyCustom/SelectEvent';
import useDarkMode from '../../../../hooks/useDarkMode';
import ModalLogs from '../../../../components/modals/LogsModal/ModalLogs';
import NFCEventData from '../../../../database/wrappers/eventData/nfc'
import NfcHistory from '../../../../database/wrappers/eventData/nfcHistory'
import dateFormat from 'dateformat';
import { orderBy } from 'firebase/firestore';

const ClientsReports = () => {
  const { darkModeStatus } = useDarkMode();
  const { estabSelected, eventSelected } = useContext(DefaultContext)
  const [openModalLogs, setOpenModalLogs] = useState(false);
  const [nfcSelected, setnfcSelected] = useState({});
  const [title, settitle] = useState('');
  const [rows, setrows] = useState([]);
  const [histories, sethistories] = useState([]);

  useEffect(() => {
    if (estabSelected && eventSelected) {
      let nfcs = []
      const onSubscriber = new NFCEventData(estabSelected, eventSelected).on(datas => {
        if (datas.length === nfcs.length) return;
        setrows(datas)
      }, orderBy('name', 'asc'))
      return () => {
        nfcs = [];
        setrows([])
        onSubscriber();
      }
    }
  }, [estabSelected, eventSelected])

  useEffect(() => {
    if (estabSelected && eventSelected && nfcSelected?.id) {
      const onSubscriber = new NfcHistory(estabSelected, eventSelected, nfcSelected.id).on(sethistories, orderBy('created_at', 'asc'))
      return () => {
        onSubscriber();
        sethistories([]);
      };
    }
  }, [estabSelected, eventSelected, nfcSelected])

  // console.log('histories', histories)

  const handleOpenModalLogs = useCallback((row) => {
    setnfcSelected(row)
    settitle(row.name + ' - ' + row.cpf)
    setOpenModalLogs(true);
  }, []);

  const handleCloseModalLogs = useCallback(() => {
    setOpenModalLogs(false);
    setnfcSelected({})
  }, []);

  const columns = useMemo(() => ([
    {
      label: 'Abertura',
      field: 'created_at',
      format: row => dateFormat(row.created_at.toDate(), 'dd/mm HH:MM'),
      formatExport: value =>  dateFormat(value.toDate(), 'dd/mm HH:MM')
    },
    {
      label: 'Cliente',
      field: 'name',
    },
    {
      label: 'Cpf',
      field: 'cpf',
    },
    {
      label: 'NFC',
      field: 'id',
    },
    {
      label: 'Ações',
      field: 'actions',
      format: (row) => (
        <Button
          color="primary"
          size="sm"
          icon='RemoveRedEye'
          onClick={() => handleOpenModalLogs(row)}
        />
      ),
      noExport: true
    },
  ]), [darkModeStatus])

  return (
    <>
      <PageWrapper title={demoPages.reports.subMenu.clientsReports.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Relatórios', to: '/reports' },
                { title: 'Relatório Clientes', to: '/reports/clientsReports' },
              ]}
            />
          </SubHeaderLeft>
        </SubHeader>

        <Page container="fluid">
          <Card>
            <CardHeader>
              <CardLabel icon='SupervisorAccount' iconColor='light'>
                <CardTitle>Relatório Clientes</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <div className='row'>
                <SelectEstablishment />
                <SelectEvent />
              </div>

              <TableCustom
                columns={columns}
                rows={rows}
                keyExtractor={item => item.id}
                fileName='Relatório-clientes'
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>


      <ModalLogs
        open={openModalLogs}
        setIsClose={handleCloseModalLogs}
        onClose={handleCloseModalLogs}
        title={title}
        type='nfc'
        data={histories}
        nfcSelected={nfcSelected}
      />

    </>
  );
};

export default ClientsReports;
