import React from "react";
import dateFormat from "dateformat";
import Footer from "../../Footer";
import { MONTH_PTBR } from "../../../types/dates";


const LastPage = ({ establishment, eventSelected }) => {


  const dataAtual = new Date();

  return (
    <div className="mt-2">
      <div className='col-xxl-12 justify-content-around px-4'>
        <h6>Por ser expressão da verdade e estarem de comum acordo com o presente termo firmam o presente.</h6>
        <h6 className='mt-2 fw-bold'>São Paulo, {dateFormat(dataAtual, 'dd')} de {MONTH_PTBR[dataAtual.getMonth()]} de {dateFormat(dataAtual, 'yyyy')}.</h6>
      </div>
      <div style={{ marginTop: '150px' }} className='divFooter'>
        <div className='d-flex text-center flex-column col-xxl-12 justify-content-around mt-report  my-5 py-3'>
          <h6>UGET PDX TECNOLOGIA E SOLUÇÕES LTDA.</h6>
          <div className='d-flex text-center flex-column'>
            <h6>Rafael Setrak Giglio</h6>
            <h6>157.419.498-43</h6>
            <h6>rafael@uget.com.br</h6>
          </div>
        </div>
        <div style={{ marginTop: '150px' }} className='d-flex text-center flex-column col-xxl-12 justify-content-around mt-report py-3'>
          <h6>CLIENTE - {establishment?.name}</h6>
          <div className='d-flex text-center flex-column'>
            <h6>{establishment?.representative?.personal_name}</h6>
            <h6>{establishment?.representative?.personal_cpf}</h6>
            <h6>{establishment?.email}</h6>
          </div>
        </div>
        <div id='ugetWitnesses' className='row justify-content-between mt-report pt-5 mb-2 mt-xxl-5 '>
          <div className='col-6  text-center'>
            <h6>TESTEMUNHA UGET</h6>
            <h6>{eventSelected?.witnesses.ugetWitnessName}</h6>
            <h6>CPF: {eventSelected?.witnesses.ugetWitnessCpf}</h6>
            <h6>E-MAIL: {eventSelected?.witnesses.ugetWitnessEmail}</h6>
          </div>
          <div className='col-6  text-center'>
            <h6>TESTEMUNHA EVENTO</h6>
            <h6>{eventSelected?.witnesses.eventWitnessName}</h6>
            <h6>CPF: {eventSelected?.witnesses.eventWitnessCpf}</h6>
            <h6>E-MAIL: {eventSelected?.witnesses.eventWitnessEmail}</h6>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default LastPage;